import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";
import ModalB from "./Componentes/ModalB";
import BarraTablas from "../../../../components/BarraTablas/BarraTablas";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";

import io from "socket.io-client";
import { socketUrl } from "../../../../config/const";
const Proveedores = (props) => {
  // const { llamadoDeOtroLugar, setopenMT } = props;
  const {
    desdeOtroLugarMenu,
    rolesSeleccionados,
    setpersonasSeleccionadas,
    // puedeExportar,
    // itemRelacionadoPadre,
  } = props;
  // DATA DEL STATE

  const [rows, setrows] = useState(null);
  const columns = desdeOtroLugarMenu
    ? [
        {
          id: "perDocNumero",
          label: "Documento",
          minWidth: 50,
          tipo: "string",
          propiedad: "perDocNumero",
        },
        {
          id: "perNombre",
          label: "Nombres",
          minWidth: 50,
          tipo: "string",
          propiedad: "perNombre",
        },
        {
          id: "perPermisos",
          label: "Permisos Personalizados",
          minWidth: 250,
          tipo: "",
          propiedad: "perPermisos",
        },
        {
          id: "perPerfil",
          label: "Roles",
          minWidth: 50,
          tipo: "",
          propiedad: "perPerfil",
        },

        {
          id: "perUser",
          label: "User",
          minWidth: 50,
          tipo: "string",
          propiedad: "perUser",
        },
        {
          id: "perFecUpd",
          label: "Fec Upd",
          minWidth: 50,
          tipo: "string",
          propiedad: "perFecUpd",
        },
      ]
    : [
        {
          id: "perDocTipo",
          label: "Tipo Doc",
          minWidth: 50,
          tipo: "string",

          propiedad: "perDocTipo",
        },
        {
          id: "perDocNumero",
          label: "Documento",
          minWidth: 50,
          tipo: "string",

          propiedad: "perDocNumero",
        },
        {
          id: "perNombre",
          label: "Nombres",
          minWidth: 50,
          tipo: "string",

          propiedad: "perNombre",
        },
        {
          id: "perTelefono",
          label: "Teléfono",
          minWidth: 50,
          tipo: "string",

          propiedad: "perTelefono",
        },
        {
          id: "perMovil",
          label: "Movil",
          minWidth: 50,
          tipo: "string",

          propiedad: "perMovil",
        },
        {
          id: "perObligado",
          label: "Obligado",
          minWidth: 50,
          tipo: "string",

          propiedad: "perObligado",
        },
        {
          id: "perEmail",
          label: "Email",
          minWidth: 50,
          tipo: "string",

          propiedad: "perEmail",
        },
        {
          id: "perUser",
          label: "User",
          minWidth: 50,
          tipo: "string",

          propiedad: "perUser",
        },
        {
          id: "perFecUpd",
          label: "Fec Upd",
          minWidth: 50,
          tipo: "string",
          propiedad: "perFecUpd",
        },
      ];

  const [ObjectoActivo, setObjetoActivo] = useState({
    perISPContratos: [],
    perNombreComercial: "",
    perEmpresa: [],
    perPais: "",
    perProvincia: "",
    perCanton: "",
    perTipoProveedor: "BIENES",
    perTiempoCredito: "0",
    perDocTipo: "",
    perDocNumero: "",
    perPerfil: [],
    perNombre: "",
    perDireccion: "",
    perObligado: "NO",
    perCredito: "NO",
    perTelefono: "0",
    perCelular: ["+593"],
    perEstado: "ACTIVO",
    perObsevacion: "",
    perEmail: ["sin@sincorreo.com"],
    perOtros: ["ZZZ9999"],
    perNickname: "",
    perUser: "",
    perFecReg: "",
    perFoto: "",
    perUbicacion: {
      longitud: "",
      latitud: "",
    },
    perDocumento: "",
    perGenero: "M",
    perRecomendacion: "OTROS",
    perFecNacimiento: "",
    perEspecialidad: "",
    perTitulo: "",
    perSenescyt: "",
    perPersonal: "INTERNO",
    validateEmail: 1,
  });
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil
  ) => {
    const res = await clienteAxios.get(
      `/proveedores/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&perfil=${
        perfil ? perfil : ""
      }&fromPermisos=${desdeOtroLugarMenu}`
    );

    return res;
  };

  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      perfil
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      perfil
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
    // dispatch({
    //   type: OBTENER,
    //   payload: {
    //     results: res.data.data.results,
    //     pagination: res.data.data.pagination,
    //   },
    // });
  };
  const agregarState = async (item) => {
    try {
      setrows([item, ...rows]);

      setpagination({
        ...pagination,
        numRows: pagination.numRows + 1,
      });
    } catch (error) {}
  };

  const editarState = async (item) => {
    try {
      const resRows = rows.map((itemR) => {
        if (itemR.perId === item.perId) {
          return item;
        } else {
          return itemR;
        }
      });

      setrows(resRows);
    } catch (error) {}
  };

  const eliminarState = async (_id) => {
    try {
      const resRows = rows.filter((row) => {
        return row.perId !== _id;
      });
      setrows(resRows);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - 1,
      });
    } catch (error) {}
  };
  const eliminarSeleccionState = async (_ids) => {
    const resRows = rows.filter((row) => {
      return !_ids.some((item) => {
        return item === row.perId;
      });
    });
    setpagination({
      ...pagination,
      numRows: pagination.numRows - _ids.length,
    });
    setrows(resRows);
  };

  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
    // dispatch({
    //   type: CHANGE_ACTIVO,
    //   payload: item,
    // });
  };

  const rowsImportState = (rows) => {
    // dispatch({
    //   type: IMPORTAR_ROWS,
    //   payload: rows,
    // });
    setrows(rows);
  };
  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  const [tabla] = useState("proveedor");
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  const socket = useRef();
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item.perId);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const {
    usuario,
    usuario: { rucempresa, empRoles },
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [arrayExport, setarrayExport] = useState([]);
  useEffect(() => {
    if (!desdeOtroLugarMenu) {
      return;
    }

    setpersonasSeleccionadas(arrayExport);
    //eslint-disable-next-line
  }, [arrayExport]);

  const [campoExport] = useState("perId");
  const [estadoRequest, setestadoRequest] = useState("CLIENTE");
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [editar, seteditar] = useState(false);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "perFecUpd",
    label: "ID",
    minWidth: 150,
    tipo: "string",

    propiedad: "perFecUpd",
  });

  const [orden, setorden] = useState(false);
  const [openMB, setopenMB] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    perFecReg1: "",
    perFecReg2: "",
  });
  //pagina actual
  const [page, setPage] = React.useState(0);
  //filas por pagina
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // input
  //
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  const handleSearch = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setcargando(false);
  };
  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      perFecReg1: "",
      perFecReg2: "",
    });
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      orden,
      JSON.stringify({
        perFecReg1: "",
        perFecReg2: "",
      }),
      rucempresa,
      estadoRequest
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const funcArchivo = async (e) => {
    if (!e.target.files[0]) {
      return;
    } else {
      if (e.target.files[0].type !== "text/plain") {
        return mostrarAlerta("El archivo no es de texto");
      } else {
        const formDataLogos = new FormData();
        formDataLogos.append("archivo", e.target.files[0]);
        formDataLogos.append("tipo", "compras");
        const res = await clienteAxios.post(
          "/uploads_comprastxt",
          formDataLogos
        );
        rowsImportState({ res });
      }
    }
  };

  return (
    <Box>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={setopen}
        open={open}
        openMB={openMB}
        setopenMB={setopenMB}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        subirArchivo={false}
        funcArchivo={funcArchivo}
        ocultarBuscar={false}
        ocultarMigasDePan={desdeOtroLugarMenu}
        ocultarAgregar={desdeOtroLugarMenu}
        ocultarOpciones={desdeOtroLugarMenu}
      />{" "}
      <ModalB
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (Personas${
          desdeOtroLugarMenu ? "" : `: ${estadoRequest}`
        })`}
        columns={columns}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        funcionDefault={funcionDefault}
      />{" "}
      {desdeOtroLugarMenu ? null : (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems=" stretch"
          width="100%"
        >
          <Box>
            {empRoles.slice(2).map((itemRol, index) => {
              return (
                <Button
                  size="small"
                  key={index}
                  variant={estadoRequest === itemRol ? "contained" : null}
                  onClick={async () => {
                    setcargando(true);
                    await remplazarRowsState(
                      rowsPerPage,
                      0,
                      "",
                      input.id,
                      orden,
                      JSON.stringify(fullBuscador),
                      rucempresa,
                      itemRol
                    );

                    setPage(0);
                    setestadoRequest(itemRol);
                    setcargando(false);
                  }}
                  disableElevation
                  style={{
                    padding: ".5rem",
                    borderRadius: "15px 15px  0px 0px",
                  }}
                  color="primary"
                >
                  {itemRol}
                </Button>
              );
            })}
          </Box>
        </Box>
      )}
      <Divider />
      <Tabla
        estadoRequest={estadoRequest}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        seteditar={seteditar}
        setopen={setopen}
        rows={rows}
        buscando={buscando}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        // STATE
        addRowsState={addRowsState}
        getDataState={getDataState}
        remplazarRowsState={remplazarRowsState}
        obtenerState={obtenerState}
        pagination={pagination}
        columns={columns}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        rowsImportState={rowsImportState}
        // export
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        socket={socket}
        desdeOtroLugarMenu={desdeOtroLugarMenu}
        rolesSeleccionados={rolesSeleccionados}
      />
      <Modal
        estadoRequest={estadoRequest}
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
      />
    </Box>
  );
};

export default memo(Proveedores);
