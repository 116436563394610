import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TableCell,
  TableRow,
  Collapse,
  Button,
  Divider,
  Typography,
  lighten,
} from "@mui/material";
import { useState } from "react";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";

import AsistenciasTecnicas from "../../../../AsistenciasTecnicas/AsistenciasTecnicas";
import Facturacion from "../../../../Ventas/Facturas/Facturacion";
import CuentasXCobrar from "../../../../Ventas/CuentasCobrar/CuentasXCobrar";
import Compras from "../../../Compras/Compras";
import CuentasPorPagar from "../../../CuantasPorCobrar/CuentasPorCobrar";
import Acta_Entrega_Recepcion from "../.../../../../../Acta_Entrega_Recepcion/Acta_Entrega_Recepcion"; 
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    estadoRequest,
    changeObjetoActivoState,
    socket,
    desdeOtroLugarMenu,
  } = props;
  const [celComprimida, setcelComprimida] = useState({});
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const { usuario } = useContext(AuthContext);
  const [expandir, setexpandir] = useState(false);
  const [seccion, setseccion] = useState({
    facturacionSeccion: false,
    CuentasXCobrarSeccion: false,
    ComprasSeccion: false,
    CuentasPorPagarSeccion: false,
    Actividades: false,
  });
  const {
    facturacionSeccion,
    CuentasXCobrarSeccion,
    ComprasSeccion,
    CuentasPorPagarSeccion,
    Actividades,
  } = seccion;
  const classes = useRowStyles();
  const [tab, settab] = useState("");
  const [arrayExportHijos, setarrayExportHijos] = useState("");

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "proveedor",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: usuario.rucempresa,
        perId: row.perId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {
    const map = arrayExport.map((itemE) => {
      if (itemE.perId === row.perId) {
        return {
          perId: itemE[campoExport],
          hijos: arrayExportHijos,
          tab,
          seccion: facturacionSeccion
            ? "VENTAS"
            : CuentasXCobrarSeccion
            ? "CUENTAS POR COBRAR"
            : ComprasSeccion
            ? "COMPRAS"
            : CuentasPorPagarSeccion
            ? "CUENTAS POR PAGAR"
            : "",
        };
      } else {
        return itemE;
      }
    });

    setarrayExport(map);
    // eslint-disable-next-line
  }, [arrayExportHijos, tab, seccion]);
  const [desdeOtroLugarPersona] = useState(true);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de pasar este usuario: ${row.perNombre} a cliente?`}
        contenido={`El proveedor ${row.perNombre} será eliminado, no podra ser recuperado`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          campoExport={campoExport}
          campoEstado="perEstado"
          expandir={expandir}
          setexpandir={setexpandir}
          ocultarExpandir={desdeOtroLugarMenu}
          ocultarEditar={desdeOtroLugarMenu}
          ocultarDelete={desdeOtroLugarMenu || estadoRequest !== ""}
          mostrarEstado={!desdeOtroLugarMenu}
        />
        {desdeOtroLugarMenu ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.perDocNumero}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="left"
              className={classes.celda}
            >
              {row.perNombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={`${classes.celda} ${classes.hover}`}
              onClick={() =>
                setcelComprimida({
                  ...celComprimida,
                  perPermisos: !celComprimida["perPermisos"],
                })
              }
            >
              {!celComprimida["perPermisos"] ? (
                <>
                  {row.perPermisos[usuario.rucempresa]
                    ? Object.entries(row.perPermisos[usuario.rucempresa])
                        .slice(0, 1)
                        .map((permisosItem, indexPermiso) => {
                          return (
                            <Box
                              display={"flex"}
                              flexWrap="wrap"
                              justifyContent={"space-between"}
                              width="100%"
                              className={
                                indexPermiso % 2 === 0
                                  ? ""
                                  : classes.celdaSelect
                              }
                            >
                              <Typography
                                fontWeight={900}
                                className={classes.celda}
                                sx={{ margin: "0 0 0 0", lineHeight: 1 }}
                              >
                                {permisosItem[0]}
                              </Typography>
                              <Typography
                                sx={{
                                  margin: "0",
                                  lineHeight: 1,
                                }}
                                component="span"
                                className={classes.celda}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                >
                                  {Object.entries(permisosItem[1]).map(
                                    (tipoPermiso) => {
                                      return (
                                        <Typography
                                          sx={{
                                            paddingLeft: "2rem",
                                            margin: "0",
                                            lineHeight: 1,
                                            textTransform: "uppercase",
                                          }}
                                          fontWeight="900"
                                          component="span"
                                          className={classes.celda}
                                        >
                                          {tipoPermiso[0]}:
                                          <Typography
                                            sx={{
                                              margin: "0",
                                              lineHeight: 1,
                                            }}
                                            component="span"
                                            className={classes.celda}
                                          >
                                            {tipoPermiso[1] === 0 ||
                                            !tipoPermiso
                                              ? "D"
                                              : tipoPermiso[1] === 1
                                              ? "SI"
                                              : "NO"}
                                          </Typography>
                                        </Typography>
                                      );
                                    }
                                  )}
                                </Box>
                              </Typography>
                              Permisos:
                              {
                                Object.entries(
                                  row.perPermisos[usuario.rucempresa]
                                ).length
                              }
                            </Box>
                          );
                        })
                    : null}
                </>
              ) : null}
              <Collapse
                in={celComprimida["perPermisos"]}
                timeout="auto"
                unmountOnExit
              >
                {row.perPermisos[usuario.rucempresa]
                  ? Object.entries(row.perPermisos[usuario.rucempresa]).map(
                      (permisosItem, indexPermiso) => {
                        return (
                          <Box
                            display={"flex"}
                            flexWrap="wrap"
                            justifyContent={"space-between"}
                            width="100%"
                            className={
                              indexPermiso % 2 === 0 ? "" : classes.celdaSelect
                            }
                          >
                            <Typography
                              fontWeight={900}
                              className={classes.celda}
                              sx={{ margin: "0 0 0 0", lineHeight: 1 }}
                            >
                              {permisosItem[0]}
                            </Typography>
                            <Typography
                              sx={{
                                margin: "0",
                                lineHeight: 1,
                              }}
                              component="span"
                              className={classes.celda}
                            >
                              <Box display={"flex"} justifyContent={"flex-end"}>
                                {Object.entries(permisosItem[1]).map(
                                  (tipoPermiso) => {
                                    return (
                                      <Typography
                                        sx={{
                                          paddingLeft: "2rem",
                                          margin: "0",
                                          lineHeight: 1,
                                          textTransform: "uppercase",
                                        }}
                                        fontWeight="900"
                                        component="span"
                                        className={classes.celda}
                                      >
                                        {tipoPermiso[0]}:
                                        <Typography
                                          sx={{
                                            margin: "0",
                                            lineHeight: 1,
                                          }}
                                          component="span"
                                          className={classes.celda}
                                        >
                                          {tipoPermiso[1] === 0 || !tipoPermiso
                                            ? "D"
                                            : tipoPermiso[1] === 1
                                            ? "SI"
                                            : "NO"}
                                        </Typography>
                                      </Typography>
                                    );
                                  }
                                )}
                              </Box>
                            </Typography>
                          </Box>
                        );
                      }
                    )
                  : null}
              </Collapse>
            </TableCell>

            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.perPerfil.join(", ")}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.perUser}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {new Date(row.perFecUpd).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.perDocTipo}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.perDocNumero}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="left"
              className={classes.celda}
            >
              {row.perNombre}
            </TableCell>
            {estadoRequest === "" ? (
              <>
                <TableCell
                  padding="none"
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  {row.validateEmail === 1 ? "SI" : "NO"}
                </TableCell>
                <TableCell
                  padding="none"
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  {`${row.ciudad} - ${row.sector}`}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell
                  padding="none"
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  $
                  {row.perISPContratos.length === 0
                    ? null
                    : row.perISPContratos
                        .map(
                          (itemContrato) =>
                            Number(itemContrato.perServicioAdicional) +
                            Number(itemContrato.perValorPlan)
                        )
                        .reduce(reducer)
                        .toFixed(2)}
                </TableCell>
                <TableCell
                  padding="none"
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  {row.perISPContratos.length === 0
                    ? null
                    : row.perISPContratos
                        .map(
                          (itemContrato) =>
                            itemContrato.perNombreContrato +
                            " - " +
                            itemContrato.perUbicacionContrato +
                            " - " +
                            itemContrato.perDiaCobro
                        )
                        .join(", ")}
                </TableCell>
                <TableCell
                  padding="none"
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  {`${row.ciudad || ""} - ${row.sector || ""}`}
                </TableCell>
              </>
            )}
            <TableCell
              padding="none"
              size="small"
              align="left"
              className={classes.celda}
            >
              {row.perCelular.join(", ")}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="left"
              className={classes.celda}
            >
              {row.perEmail.join(", ")}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.perUser}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {new Date(row.perFecUpd).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
          sx={{
            bgcolor: (theme) => lighten(theme.palette.secondary.light, 0.9),
          }}
        >
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
            >
              <Box>
                {estadoRequest === "CLIENTE" ? (
                  <>
                    <Button
                      size="small"
                      variant={facturacionSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ facturacionSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Ventas
                    </Button>
                    <Button
                      size="small"
                      variant={CuentasXCobrarSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ CuentasXCobrarSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Cuentas Por Cobrar
                    </Button>
                    <Button
                      size="small"
                      variant={Actividades ? "contained" : null}
                      onClick={async () => {
                        setseccion({ Actividades: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Actividades
                    </Button>
                  </>
                ) : null}
                {estadoRequest === "PROVEEDOR" ? (
                  <>
                    <Button
                      size="small"
                      variant={ComprasSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ ComprasSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Compras
                    </Button>
                    <Button
                      size="small"
                      variant={CuentasPorPagarSeccion ? "contained" : null}
                      onClick={async () => {
                        setseccion({ CuentasPorPagarSeccion: true });
                        settab("");
                        setarrayExportHijos([]);
                      }}
                      disableElevation
                      style={{
                        padding: ".5rem",
                        borderRadius: "15px 15px  0px 0px",
                      }}
                      color="secondary"
                    >
                      Cuentas Por Pagar
                    </Button>
                  </>
                ) : null}
              </Box>
            </Box>
            <Divider style={{ display: "block", width: "100%" }} />
            <Box p={0.5} sx={{}}>
              {estadoRequest === "CLIENTE" ? (
                <>
                  {facturacionSeccion ? (
                    <Facturacion
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                  {CuentasXCobrarSeccion ? (
                    <CuentasXCobrar
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                  {Actividades ? (
                    <Acta_Entrega_Recepcion idPer_frompersonas={row.perId} />
                  ) : null}
                </>
              ) : null}
              {estadoRequest === "PROVEEDOR" ? (
                <>
                  {ComprasSeccion ? (
                    <Compras
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                  {CuentasPorPagarSeccion ? (
                    <CuentasPorPagar
                      documento={row.perDocNumero}
                      settab={settab}
                      setarrayExportHijos={setarrayExportHijos}
                      desdeOtroLugarPersona={desdeOtroLugarPersona}
                      itemRelacionadoPadre={row}
                    />
                  ) : null}
                </>
              ) : null}
              {estadoRequest === "TECNICO" ? (
                <>
                  <AsistenciasTecnicas
                    documento={row.perDocNumero}
                    settab={settab}
                    setarrayExportHijos={setarrayExportHijos}
                    desdeOtroLugarPersona={desdeOtroLugarPersona}
                    itemRelacionadoPadre={row}
                  />
                </>
              ) : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
